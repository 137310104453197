<template>
	<div id="clinic">
		<v-head></v-head>
		<router-view></router-view>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		},
	};
</script>

<style lang="scss" scoped>
   #clinic {
	   width: 100%;
	   padding-top: 83px;
   }
</style>
